import React, { useState, useEffect } from 'react'
import './App.css'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import styles from './styles/HorizontalScrolling.module.css'

import { HorizontalScrollingWrapper } from './components/HorizontalScrollingWrapper'
import { Sidebar } from './components/Sidebar'
import { getPageData } from './utils/getPageData'
import { NFTContent } from './content/NFTContent'
import { Secondary } from './components/Navigation/Secondary'
import { standardizeClassName } from './utils/standardizeClassName'

const NFT = ({ location, isMobile }) => {
  // const history = useHistory()
  // const [visibleContent, setVisibleContent] = useState([])
  // const [projects, setProjects] = useState([])
  // const [numberOfImagesLoaded, setnumberOfImagesLoaded] = useState(0)
  // const { pathname, search } = location
  // const { category, position } = queryString.parse(location.search)
  // const isNFTPage = pathname === '/NFTS' || pathname === '/NFTS/'
  // useEffect(() => {
  //   setProjects([])
  // }, [location])

  // useEffect(() => {
  //   const projects = NFTContent.filter(page => page.category === category)[0].projects
  //   setProjects(projects)
  // }, [projects])

  // useEffect(() => {
  //   if (position && numberOfImagesLoaded === projects.length) {
  //     const item = document.querySelector(`.${position}`)
  //     item?.scrollIntoView({ inline: 'center' })
  //   } 
  // }, [numberOfImagesLoaded])

  // const handleElementOnScreen = element => {
  //   element.style.opacity = 1
  //   const index = Number(element?.getAttribute('index'))
  //   const projects = getPageData({ location, isNFTPage })[index]
  //   element.src = projects.image

  //   setVisibleContent({ ...projects })
  // }

  // const handleMoreInfoEvent = title => {
  //   history.push(`/details?category=${category}&title=${title}&type=${isNFTPage ? 'NFT' : 'print'}`)
  // }

  // const setImagesLoaded = () => {
  //   setnumberOfImagesLoaded(numberOfImagesLoaded + 1)
  // }

  return (
    <div className='shop-wrapper-padding'>
      <div className={styles.center}>
    <h1 class={styles.glitch}>
      <span aria-hidden="true">NFTS COMING SOON</span>
      NFTS COMING SOON
      <span aria-hidden="true">NFTS COMING SOON</span>
    </h1>
    </div>
      {/* {projects.length && (
        <HorizontalScrollingWrapper handleElementOnScreen={handleElementOnScreen} isMobile={isMobile}>
          {projects?.map((NFT, index) => (
            <>
              <div
                className={`${styles.imageContainer}`}
                onClick={() => handleMoreInfoEvent(NFT.title)}
              >
                <img
                  src={`/images/placeholders/${category}/${[index]}.svg`}
                  alt={NFT.alt}
                  index={index}
                  className={`image image-index-container ${standardizeClassName(NFT.title)}`}
                  loaded={false}
                  onLoad={setImagesLoaded}
                />
                <div className={styles.moreInfoContainer}>
                  <p className={styles.moreDetails} onClick={() => handleMoreInfoEvent(NFT.title)}>
                    MORE INFO
                  </p>
                </div>
              </div>
            </>
          ))}
        </HorizontalScrollingWrapper>
      )} */}

      {/* <Sidebar
        title={visibleContent?.title}
        price={`£${visibleContent?.NFTPrice}`}
        NFTLink={visibleContent?.NFTLink}
        sold={visibleContent?.sold}
      />
      <Secondary pathname={pathname} search={search} showSecondaryNav="showSecondaryNav" /> */}
    </div>
  )
}

export default NFT
