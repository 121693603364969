import workIsHell0 from '../../images/work_is_hell/0.jpg'
import workIsHell1 from '../../images/work_is_hell/1.jpg'
import workIsHell2 from '../../images/work_is_hell/2.jpg'
import workIsHell3 from '../../images/work_is_hell/3.jpg'
import workIsHell4 from '../../images/work_is_hell/4.jpg'
import workIsHell5 from '../../images/work_is_hell/5.jpg'
import workIsHell6 from '../../images/work_is_hell/6.jpg'
import workIsHell7 from '../../images/work_is_hell/7.jpg'
import workIsHell8 from '../../images/work_is_hell/8.jpg'
import workIsHell10 from '../../images/work_is_hell/10.jpg'
import workIsHell11 from '../../images/work_is_hell/11.jpg'
import workIsHell12 from '../../images/work_is_hell/12.jpg'
import workIsHell13 from '../../images/work_is_hell/13.jpg'
import workIsHell14 from '../../images/work_is_hell/14.jpg'
import workIsHell15 from '../../images/work_is_hell/15.jpg'
import workIsHell16 from '../../images/work_is_hell/16.jpg'
import workIsHell17 from '../../images/work_is_hell/17.jpg'

import workIsHellLarge0 from '../../images/work_is_hell/large/0.jpg'
import workIsHellLarge1 from '../../images/work_is_hell/large/1.jpg'
import workIsHellLarge2 from '../../images/work_is_hell/large/2.jpg'
import workIsHellLarge3 from '../../images/work_is_hell/large/3.jpg'
import workIsHellLarge4 from '../../images/work_is_hell/large/4.jpg'
import workIsHellLarge5 from '../../images/work_is_hell/large/5.jpg'
import workIsHellLarge6 from '../../images/work_is_hell/large/6.jpg'
import workIsHellLarge7 from '../../images/work_is_hell/large/7.jpg'
import workIsHellLarge8 from '../../images/work_is_hell/large/8.jpg'
import workIsHellLarge10 from '../../images/work_is_hell/large/10.jpg'
import workIsHellLarge11 from '../../images/work_is_hell/large/11.jpg'
import workIsHellLarge12 from '../../images/work_is_hell/large/12.jpg'
import workIsHellLarge13 from '../../images/work_is_hell/large/13.jpg'
import workIsHellLarge14 from '../../images/work_is_hell/large/14.jpg'
import workIsHellLarge15 from '../../images/work_is_hell/large/15.jpg'
import workIsHellLarge16 from '../../images/work_is_hell/large/16.jpg'
import workIsHellLarge17 from '../../images/work_is_hell/large/17.jpg'

import workIsHellPlaceholder0 from '../../images/work_is_hell/placeholder/0.svg'
import workIsHellPlaceholder1 from '../../images/work_is_hell/placeholder/1.svg'
import workIsHellPlaceholder2 from '../../images/work_is_hell/placeholder/2.svg'
import workIsHellPlaceholder3 from '../../images/work_is_hell/placeholder/3.svg'
import workIsHellPlaceholder4 from '../../images/work_is_hell/placeholder/4.svg'
import workIsHellPlaceholder5 from '../../images/work_is_hell/placeholder/5.svg'
import workIsHellPlaceholder6 from '../../images/work_is_hell/placeholder/6.svg'
import workIsHellPlaceholder7 from '../../images/work_is_hell/placeholder/7.svg'
import workIsHellPlaceholder8 from '../../images/work_is_hell/placeholder/8.svg'
import workIsHellPlaceholder10 from '../../images/work_is_hell/placeholder/10.svg'
import workIsHellPlaceholder11 from '../../images/work_is_hell/placeholder/11.svg'
import workIsHellPlaceholder12 from '../../images/work_is_hell/placeholder/12.svg'
import workIsHellPlaceholder13 from '../../images/work_is_hell/placeholder/13.svg'
import workIsHellPlaceholder14 from '../../images/work_is_hell/placeholder/14.svg'
import workIsHellPlaceholder15 from '../../images/work_is_hell/placeholder/15.svg'
import workIsHellPlaceholder16 from '../../images/work_is_hell/placeholder/16.svg'
import workIsHellPlaceholder17 from '../../images/work_is_hell/placeholder/17.svg'

export const workIsHell = [
    {
        category: 'work_is_hell',
        projects: [
          {
            title: 'Manager',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell6,
            imageLarge: workIsHellLarge6,
            imagePlaceholder: workIsHellPlaceholder6,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wM9E5gZCgx62GIcPc',
          },
          {
            title: "Your_Job_Doesn't_Care_About_You",
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell0,
            imageLarge: workIsHellLarge0,
            imagePlaceholder: workIsHellPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE8A1fVy80A6WY2as',
          },
          {
            title: 'Mortgage_Approved',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell1,
            imageLarge: workIsHellLarge1,
            imagePlaceholder: workIsHellPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/7sIdUlaBe3KkepqcP7',
          },
          {
            title: 'Police',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell2,
            imageLarge: workIsHellLarge2,
            imagePlaceholder: workIsHellPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEU03veRubcMftu8yS',
          },
          {
            title: 'Socialising',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell3,
            imageLarge: workIsHellLarge3,
            imagePlaceholder: workIsHellPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIYeYp24I6Ww0yAeXh',
          },
          {
            title: 'Careers_Officer',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell4,
            imageLarge: workIsHellLarge4,
            imagePlaceholder: workIsHellPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEU03vcJmcgQbdeeXi',
          },
          {
            title: 'On_the_Train',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell5,
            imageLarge: workIsHellLarge5,
            imagePlaceholder: workIsHellPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEU5nP6kY1Cc3KM6qN',
          },
          {
            title: 'New_Airpods',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell7,
            imageLarge: workIsHellLarge7,
            imagePlaceholder: workIsHellPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEUbMdaBecgQdlmcPd',
          },
          {
            title: 'Time_Off',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell8,
            imageLarge: workIsHellLarge8,
            imagePlaceholder: workIsHellPlaceholder8,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28o7vXeRu6Ww5SU8yY',
          },
          {
            title: 'Mother_and_Child',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell10,
            imageLarge: workIsHellLarge10,
            imagePlaceholder: workIsHellPlaceholder10,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/4gweYp24I80Aftu2aC',
          },
          {
            title: 'Mask',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell11,
            imageLarge: workIsHellLarge11,
            imagePlaceholder: workIsHellPlaceholder11,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQcQh10E5Ss1CE16z',
          },
          {
            title: 'NET_OF_FEAR',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell12,
            imageLarge: workIsHellLarge12,
            imagePlaceholder: workIsHellPlaceholder12,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/00gg2taBecgQ3KM6s6',
          },
          {
            title: 'SEATED WOMAN',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell13,
            imageLarge: workIsHellLarge13,
            imagePlaceholder: workIsHellPlaceholder13,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wM4jL38M4Oo956aIn',
          },
          {
            title: 'YOUR_BOSS_IS_NOT_YOUR_BOSS',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell14,
            imageLarge: workIsHellLarge14,
            imagePlaceholder: workIsHellPlaceholder14,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28o7vX10E4Oo5SU9Ek',
          },
          {
            title: 'DIGITAL_DISCONNECTED',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell15,
            imageLarge: workIsHellLarge15,
            imagePlaceholder: workIsHellPlaceholder15,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28o03vcJmbcM8129El',
          },
          {
            title: 'JOBS_ARE_HELL',
            pricePrint: 40,
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell16,
            imageLarge: workIsHellLarge16,
            imagePlaceholder: workIsHellPlaceholder16,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIY03v4cQ0y84OQ3fY',
          },
          {
            title: 'UNDER_THE_SATELLITE',
            priceOriginal: 200,
            material: 'Pen on paper',
            sold: false,
            dimensions: 'A4',
            image: workIsHell17,
            imageLarge: workIsHellLarge17,
            imagePlaceholder: workIsHellPlaceholder17,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE17z24I2Gg1CE6sb',
          },
        ],
    },
]