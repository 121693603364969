import multiPen0 from '../../images/multi_pen/0.jpg'
import multiPen1 from '../../images/multi_pen/1.jpg'
import multiPen2 from '../../images/multi_pen/2.jpg'
import multiPen3 from '../../images/multi_pen/3.jpg'
import multiPen4 from '../../images/multi_pen/4.jpg'
import multiPen5 from '../../images/multi_pen/5.jpg'
import multiPen6 from '../../images/multi_pen/6.jpg'
import multiPen7 from '../../images/multi_pen/7.jpg'
import multiPen8 from '../../images/multi_pen/8.jpg'
import multiPen9 from '../../images/multi_pen/9.jpg'
import multiPen10 from '../../images/multi_pen/10.jpg'
import multiPen11 from '../../images/multi_pen/11.jpg'
import multiPen12 from '../../images/multi_pen/12.jpg'
import multiPen13 from '../../images/multi_pen/13.jpg'
import multiPen14 from '../../images/multi_pen/14.jpg'
import multiPen15 from '../../images/multi_pen/15.jpg'
import multiPen16 from '../../images/multi_pen/16.jpg'
import multiPen17 from '../../images/multi_pen/17.jpg'
import multiPen18 from '../../images/multi_pen/18.jpg'
import multiPen19 from '../../images/multi_pen/19.jpg'
import multiPen20 from '../../images/multi_pen/20.jpg'
import multiPen21 from '../../images/multi_pen/21.jpg'
import multiPen22 from '../../images/multi_pen/22.jpg'
import multiPen23 from '../../images/multi_pen/23.jpg'
import multiPen24 from '../../images/multi_pen/24.jpg'

import multiPenLarge0 from '../../images/multi_pen/large/0.jpg'
import multiPenLarge1 from '../../images/multi_pen/large/1.jpg'
import multiPenLarge2 from '../../images/multi_pen/large/2.jpg'
import multiPenLarge3 from '../../images/multi_pen/large/3.jpg'
import multiPenLarge4 from '../../images/multi_pen/large/4.jpg'
import multiPenLarge5 from '../../images/multi_pen/large/5.jpg'
import multiPenLarge6 from '../../images/multi_pen/large/6.jpg'
import multiPenLarge7 from '../../images/multi_pen/large/7.jpg'
import multiPenLarge8 from '../../images/multi_pen/large/8.jpg'
import multiPenLarge9 from '../../images/multi_pen/large/9.jpg'
import multiPenLarge10 from '../../images/multi_pen/large/10.jpg'
import multiPenLarge11 from '../../images/multi_pen/large/11.jpg'
import multiPenLarge12 from '../../images/multi_pen/large/12.jpg'
import multiPenLarge13 from '../../images/multi_pen/large/13.jpg'
import multiPenLarge14 from '../../images/multi_pen/large/14.jpg'
import multiPenLarge15 from '../../images/multi_pen/large/15.jpg'
import multiPenLarge16 from '../../images/multi_pen/large/16.jpg'
import multiPenLarge17 from '../../images/multi_pen/large/17.jpg'
import multiPenLarge18 from '../../images/multi_pen/large/18.jpg'
import multiPenLarge19 from '../../images/multi_pen/large/19.jpg'
import multiPenLarge20 from '../../images/multi_pen/large/20.jpg'
import multiPenLarge21 from '../../images/multi_pen/large/21.jpg'
import multiPenLarge22 from '../../images/multi_pen/large/22.jpg'
import multiPenLarge23 from '../../images/multi_pen/large/23.jpg'
import multiPenLarge24 from '../../images/multi_pen/large/24.jpg'

import multiPenPlaceholder0 from '../../images/multi_pen/placeholder/0.svg'
import multiPenPlaceholder1 from '../../images/multi_pen/placeholder/1.svg'
import multiPenPlaceholder2 from '../../images/multi_pen/placeholder/2.svg'
import multiPenPlaceholder3 from '../../images/multi_pen/placeholder/3.svg'
import multiPenPlaceholder4 from '../../images/multi_pen/placeholder/4.svg'
import multiPenPlaceholder5 from '../../images/multi_pen/placeholder/5.svg'
import multiPenPlaceholder6 from '../../images/multi_pen/placeholder/6.svg'
import multiPenPlaceholder7 from '../../images/multi_pen/placeholder/7.svg'
import multiPenPlaceholder8 from '../../images/multi_pen/placeholder/8.svg'
import multiPenPlaceholder9 from '../../images/multi_pen/placeholder/9.svg'
import multiPenPlaceholder10 from '../../images/multi_pen/placeholder/10.svg'
import multiPenPlaceholder11 from '../../images/multi_pen/placeholder/11.svg'
import multiPenPlaceholder12 from '../../images/multi_pen/placeholder/12.svg'
import multiPenPlaceholder13 from '../../images/multi_pen/placeholder/13.svg'
import multiPenPlaceholder14 from '../../images/multi_pen/placeholder/14.svg'
import multiPenPlaceholder15 from '../../images/multi_pen/placeholder/15.svg'
import multiPenPlaceholder16 from '../../images/multi_pen/placeholder/16.svg'
import multiPenPlaceholder17 from '../../images/multi_pen/placeholder/17.svg'
import multiPenPlaceholder18 from '../../images/multi_pen/placeholder/18.svg'
import multiPenPlaceholder19 from '../../images/multi_pen/placeholder/19.svg'
import multiPenPlaceholder20 from '../../images/multi_pen/placeholder/20.svg'
import multiPenPlaceholder21 from '../../images/multi_pen/placeholder/21.svg'
import multiPenPlaceholder22 from '../../images/multi_pen/placeholder/22.svg'
import multiPenPlaceholder23 from '../../images/multi_pen/placeholder/23.svg'
import multiPenPlaceholder24 from '../../images/multi_pen/placeholder/24.svg'

export const multiPen = [
    {
        category: 'multi_pen',
        projects: [
          {
            title: 'MULTI_PEN_1',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen0,
            imageLarge: multiPenLarge0,
            imagePlaceholder: multiPenPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZe03veRueoY4OQcQ4',
          },
          {
            title: 'MULTI_PEN_2',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen1,
            imageLarge: multiPenLarge1,
            imagePlaceholder: multiPenPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEU4jL4cQ3Kkftu17n',
          },
          {
            title: 'MULTI_PEN_3',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen2,
            imageLarge: multiPenLarge2,
            imagePlaceholder: multiPenPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wMbMd38MeoYftueYe',
          },
          {
            title: 'MULTI_PEN_4',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen3,
            imageLarge: multiPenLarge3,
            imagePlaceholder: multiPenPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/dR66rTaBea8I0yA03l',
          },
          {
            title: 'MULTI_PEN_5',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen4,
            imageLarge: multiPenLarge4,
            imagePlaceholder: multiPenPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3csbMdgZC5Ss6WY8zT',
          },
          {
            title: 'MULTI_PEN_6',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen5,
            imageLarge: multiPenLarge5,
            imagePlaceholder: multiPenPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQ6rT7p22Gg0yAg2m',
          },
          {
            title: 'MULTI_PEN_7',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen6,
            imageLarge: multiPenLarge6,
            imagePlaceholder: multiPenPlaceholder6,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/eVa6rT24I2Gggxy7vR',
          },
          {
            title: 'MULTI_PEN_8',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen7,
            imageLarge: multiPenLarge7,
            imagePlaceholder: multiPenPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/00g03v24IeoY1CE17u',
          },
          {
            title: 'MULTI_PEN_9',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen8,
            imageLarge: multiPenLarge8,
            imagePlaceholder: multiPenPlaceholder8,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/7sI5nPdNq5Ssbde9E1',
          },
          {
            title: 'MULTI_PEN_10',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen9,
            imageLarge: multiPenLarge9,
            imagePlaceholder: multiPenPlaceholder9,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3csg2t24I4Oo0yA4jI',
          },
          {
            title: 'MULTI_PEN_11',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen10,
            imageLarge: multiPenLarge10,
            imagePlaceholder: multiPenPlaceholder10,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14k9E5dNq2Ggftu5nN',
          },
          {
            title: 'MULTI_PEN_12',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen11,
            imageLarge: multiPenLarge11,
            imagePlaceholder: multiPenPlaceholder11,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wMbMd9xabcM3KM4jK',
          },
          {
            title: 'MULTI_PEN_13',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen12,
            imageLarge: multiPenLarge12,
            imagePlaceholder: multiPenPlaceholder12,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/cN23fHeRugx6a9a9E5',
          },
          {
            title: 'MULTI_PEN_14',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen13,
            imageLarge: multiPenLarge13,
            imagePlaceholder: multiPenPlaceholder13,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/cN28A1gZCdkU956eYq',
          },
          {
            title: 'MULTI_PEN_15',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen14,
            imageLarge: multiPenLarge14,
            imagePlaceholder: multiPenPlaceholder14,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oEbMdbFi5SsdlmcQj',
          },
          {
            title: 'MULTI_PEN_16',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen15,
            imageLarge: multiPenLarge15,
            imagePlaceholder: multiPenPlaceholder15,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZe17z7p280Agxy2bG',
          },
          {
            title: 'MULTI_PEN_17',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen16,
            imageLarge: multiPenLarge16,
            imagePlaceholder: multiPenPlaceholder16,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/dR68A16kY1Cc2GIg2x',
          },
          {
            title: 'MULTI_PEN_18',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen17,
            imageLarge: multiPenLarge17,
            imagePlaceholder: multiPenPlaceholder17,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14k8A18t6bcM0yAaIe',
          },
          {
            title: 'MULTI_PEN_19',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen18,
            imageLarge: multiPenLarge18,
            imagePlaceholder: multiPenPlaceholder18,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3csbMd9xadkU1CE2bJ',
          },
          {
            title: 'MULTI_PEN_20',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen19,
            imageLarge: multiPenLarge19,
            imagePlaceholder: multiPenPlaceholder19,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/eVabMddNq0y8gxy2bK',
          },
          {
            title: 'MULTI_PEN_21',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen20,
            imageLarge: multiPenLarge20,
            imagePlaceholder: multiPenPlaceholder20,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wM03v7p280A4OQ8A9',
          },
          {
            title: 'MULTI_PEN_22',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen21,
            imageLarge: multiPenLarge21,
            imagePlaceholder: multiPenPlaceholder21,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kAeYp8t64Oo5SU9Ee',
          },
          {
            title: 'MULTI_PEN_23',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen22,
            imageLarge: multiPenLarge22,
            imagePlaceholder: multiPenPlaceholder22,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/7sI3fHdNq2Gg956dUv',
          },
          {
            title: 'MULTI_PEN_24',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen23,
            imageLarge: multiPenLarge23,
            imagePlaceholder: multiPenPlaceholder23,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wMeYpeRu6WwgxyeYA',
          },
          {
            title: 'MULTI_PEN_25',
            priceOriginal: 100,
            material: 'Felt pen on paper',
            sold: false,
            dimensions: 'A4',
            image: multiPen24,
            imageLarge: multiPenLarge24,
            imagePlaceholder: multiPenPlaceholder24,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oEaI9eRu3Kk956bMp',
          },
        ],
      },
]