import editedAds0 from '../../images/edited_ads/0.jpg'
import editedAds1 from '../../images/edited_ads/1.jpg'
import editedAds2 from '../../images/edited_ads/2.jpg'
import editedAds3 from '../../images/edited_ads/3.jpg'
import editedAds4 from '../../images/edited_ads/4.jpg'
import editedAds5 from '../../images/edited_ads/5.jpg'
import editedAds6 from '../../images/edited_ads/6.jpg'
import editedAds7 from '../../images/edited_ads/7.jpg'
import editedAds8 from '../../images/edited_ads/8.jpg'
import editedAds9 from '../../images/edited_ads/9.jpg'
import editedAds10 from '../../images/edited_ads/10.jpg'
import editedAds11 from '../../images/edited_ads/11.jpg'
import editedAds12 from '../../images/edited_ads/12.jpg'
import editedAds13 from '../../images/edited_ads/13.jpg'
import editedAds14 from '../../images/edited_ads/14.jpg'
import editedAds15 from '../../images/edited_ads/15.jpg'
import editedAds16 from '../../images/edited_ads/16.jpg'
import editedAds17 from '../../images/edited_ads/17.jpg'
import editedAds18 from '../../images/edited_ads/18.jpg'
import editedAds19 from '../../images/edited_ads/19.jpg'
import editedAds20 from '../../images/edited_ads/20.jpg'
import editedAds21 from '../../images/edited_ads/21.jpg'
import editedAds22 from '../../images/edited_ads/22.jpg'
import editedAds23 from '../../images/edited_ads/23.jpg'
import editedAds24 from '../../images/edited_ads/24.jpg'
import editedAds25 from '../../images/edited_ads/25.jpg'
import editedAds26 from '../../images/edited_ads/26.jpg'
import editedAds27 from '../../images/edited_ads/27.jpg'

import editedAdsLarge0 from '../../images/edited_ads/large/0.jpg'
import editedAdsLarge1 from '../../images/edited_ads/large/1.jpg'
import editedAdsLarge2 from '../../images/edited_ads/large/2.jpg'
import editedAdsLarge3 from '../../images/edited_ads/large/3.jpg'
import editedAdsLarge4 from '../../images/edited_ads/large/4.jpg'
import editedAdsLarge5 from '../../images/edited_ads/large/5.jpg'
import editedAdsLarge6 from '../../images/edited_ads/large/6.jpg'
import editedAdsLarge7 from '../../images/edited_ads/large/7.jpg'
import editedAdsLarge8 from '../../images/edited_ads/large/8.jpg'
import editedAdsLarge9 from '../../images/edited_ads/large/9.jpg'
import editedAdsLarge10 from '../../images/edited_ads/large/10.jpg'
import editedAdsLarge11 from '../../images/edited_ads/large/11.jpg'
import editedAdsLarge12 from '../../images/edited_ads/large/12.jpg'
import editedAdsLarge13 from '../../images/edited_ads/large/13.jpg'
import editedAdsLarge14 from '../../images/edited_ads/large/14.jpg'
import editedAdsLarge15 from '../../images/edited_ads/large/15.jpg'
import editedAdsLarge16 from '../../images/edited_ads/large/16.jpg'
import editedAdsLarge17 from '../../images/edited_ads/large/17.jpg'
import editedAdsLarge18 from '../../images/edited_ads/large/18.jpg'
import editedAdsLarge19 from '../../images/edited_ads/large/19.jpg'
import editedAdsLarge20 from '../../images/edited_ads/large/20.jpg'
import editedAdsLarge21 from '../../images/edited_ads/large/21.jpg'
import editedAdsLarge22 from '../../images/edited_ads/large/22.jpg'
import editedAdsLarge23 from '../../images/edited_ads/large/23.jpg'
import editedAdsLarge24 from '../../images/edited_ads/large/24.jpg'
import editedAdsLarge25 from '../../images/edited_ads/large/25.jpg'
import editedAdsLarge26 from '../../images/edited_ads/large/26.jpg'
import editedAdsLarge27 from '../../images/edited_ads/large/27.jpg'

import editedAdsPlaceholder0 from '../../images/edited_ads/placeholder/0.svg'
import editedAdsPlaceholder1 from '../../images/edited_ads/placeholder/1.svg'
import editedAdsPlaceholder2 from '../../images/edited_ads/placeholder/2.svg'
import editedAdsPlaceholder3 from '../../images/edited_ads/placeholder/3.svg'
import editedAdsPlaceholder4 from '../../images/edited_ads/placeholder/4.svg'
import editedAdsPlaceholder5 from '../../images/edited_ads/placeholder/5.svg'
import editedAdsPlaceholder6 from '../../images/edited_ads/placeholder/6.svg'
import editedAdsPlaceholder7 from '../../images/edited_ads/placeholder/7.svg'
import editedAdsPlaceholder8 from '../../images/edited_ads/placeholder/8.svg'
import editedAdsPlaceholder9 from '../../images/edited_ads/placeholder/9.svg'
import editedAdsPlaceholder10 from '../../images/edited_ads/placeholder/10.svg'
import editedAdsPlaceholder11 from '../../images/edited_ads/placeholder/11.svg'
import editedAdsPlaceholder12 from '../../images/edited_ads/placeholder/12.svg'
import editedAdsPlaceholder13 from '../../images/edited_ads/placeholder/13.svg'
import editedAdsPlaceholder14 from '../../images/edited_ads/placeholder/14.svg'
import editedAdsPlaceholder15 from '../../images/edited_ads/placeholder/15.svg'
import editedAdsPlaceholder16 from '../../images/edited_ads/placeholder/16.svg'
import editedAdsPlaceholder17 from '../../images/edited_ads/placeholder/17.svg'
import editedAdsPlaceholder18 from '../../images/edited_ads/placeholder/18.svg'
import editedAdsPlaceholder19 from '../../images/edited_ads/placeholder/19.svg'
import editedAdsPlaceholder20 from '../../images/edited_ads/placeholder/20.svg'
import editedAdsPlaceholder21 from '../../images/edited_ads/placeholder/21.svg'
import editedAdsPlaceholder22 from '../../images/edited_ads/placeholder/22.svg'
import editedAdsPlaceholder23 from '../../images/edited_ads/placeholder/23.svg'
import editedAdsPlaceholder24 from '../../images/edited_ads/placeholder/24.svg'
import editedAdsPlaceholder25 from '../../images/edited_ads/placeholder/25.svg'
import editedAdsPlaceholder26 from '../../images/edited_ads/placeholder/26.svg'
import editedAdsPlaceholder27 from '../../images/edited_ads/placeholder/27.svg'

export const editedAds = [
    {
        category: 'edited_ads',
        projects: [
          {
            title: 'Front_Row',
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds7,
            imageLarge: editedAdsLarge7,
            imagePlaceholder: editedAdsPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIYg2t9xa3Kk2GI4jb',
          },
          {
            title: 'I_am_Loved',
            priceOriginal: 175,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds24,
            imageLarge: editedAdsLarge24,
            imagePlaceholder: editedAdsPlaceholder24,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZeaI9gZC3Kk6WY4hY',
          },
          {
            title: 'Peace',
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds9,
            imageLarge: editedAdsLarge9,
            imagePlaceholder: editedAdsPlaceholder9,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQ8A1dNq5SsdlmaGl',
          },
          {
            title: 'You_make_my_Love_come_Down',
            priceOriginal: 250,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds16,
            imageLarge: editedAdsLarge16,
            imagePlaceholder: editedAdsPlaceholder16,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/cN203v10E0y83KMg0H',
          },
          {
            title: 'Abundance',
            priceOriginal: 175,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds4,
            imageLarge: editedAdsLarge4,
            imagePlaceholder: editedAdsPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/7sI17zgZC5Sschi2aa',
          },
          {
            title: 'I_Accept_Myself',
            priceOriginal: 250,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds5,
            imageLarge: editedAdsLarge5,
            imagePlaceholder: editedAdsPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wMcQhdNqbcMbde2ac',
          },
          {
            title: 'Gucci_Detail_1 ',
            priceOriginal: 300,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds6,
            imageLarge: editedAdsLarge6,
            imagePlaceholder: editedAdsPlaceholder6,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/7sIg2t9xacgQ1CE9Co',
          },
          {
            title: "I'm_Really_Here",
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds0,
            imageLarge: editedAdsLarge0,
            imagePlaceholder: editedAdsPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/eVaaI97p22Gg5SU3ed',
          },
          {
            title: 'Poppy',
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds8,
            imageLarge: editedAdsLarge8,
            imagePlaceholder: editedAdsPlaceholder8,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE6rT8t66Wwbde4i9',
          },
          {
            title: 'Larder_full_of_Prada',
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds10,
            imageLarge: editedAdsLarge10,
            imagePlaceholder: editedAdsPlaceholder10,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIY03vaBe4OodlmaGA',
          },
          {
            title: "Ain't_Laurent",
            priceOriginal: 500,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds11,
            imageLarge: editedAdsLarge11,
            imagePlaceholder: editedAdsPlaceholder11,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQdUl7p24Oo5SUeWT',
          },
          {
            title: 'faith',
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: true,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds12,
            imageLarge: editedAdsLarge12,
            imagePlaceholder: editedAdsPlaceholder12,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kAg2tgZC4Oo3KMbKA',
          },
          {
            title: 'sore_winner',
            priceOriginal: 100,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds13,
            imageLarge: editedAdsLarge13,
            imagePlaceholder: editedAdsPlaceholder13,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/7sI4jL10E0y8ftubKF',
          },
          {
            title: "Gucci_Detail_2",
            priceOriginal: 300,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds14,
            imageLarge: editedAdsLarge14,
            imagePlaceholder: editedAdsPlaceholder14,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kA5nPcJmgx61CEcOB',
          },
          {
            title: 'Green_Crown',
            priceOriginal: 250,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds15,
            imageLarge: editedAdsLarge15,
            imagePlaceholder: editedAdsPlaceholder15,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQaI9dNqbcMftug0J',
          },
          {
            title: 'Hey_Arnold!',
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds17,
            imageLarge: editedAdsLarge17,
            imagePlaceholder: editedAdsPlaceholder17,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/4gw7vX10EbcM8125m7',
          },
          {
            title: 'The_Physical_World',
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds18,
            imageLarge: editedAdsLarge18,
            imagePlaceholder: editedAdsPlaceholder18,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE6rTcJm94E8123e7',
          },
          {
            title: 'Do_Not_Draw', 
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds19,
            imageLarge: editedAdsLarge19,
            imagePlaceholder: editedAdsPlaceholder19,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14keYp8t680A5SUg11',
          },
          {
            title: 'Model_Portrait', 
            priceOriginal: 150,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds20,
            imageLarge: editedAdsLarge20,
            imagePlaceholder: editedAdsPlaceholder20,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/4gw8A1eRu1Cc9566qa',
          },
          {
            title: "Occulted_Knowledge",
            priceOriginal: 250,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds21,
            imageLarge: editedAdsLarge21,
            imagePlaceholder: editedAdsPlaceholder21,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3cs8A17p22Gga9a01R',
          },
          {
            title: 'Tell_me_what_you_Know',
            priceOriginal: 150,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds22,
            imageLarge: editedAdsLarge22,
            imagePlaceholder: editedAdsPlaceholder22,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3cscQh6kYeoYgxyeWK',
          },
          {
            title: 'Absurdity_beneath_Solemnity',
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds23,
            imageLarge: editedAdsLarge23,
            imagePlaceholder: editedAdsPlaceholder23,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oEdUl7p2gx65SU4ie',
          },
          {
            title: "Serial_Person", 
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds25,
            imageLarge: editedAdsLarge25,
            imagePlaceholder: editedAdsPlaceholder25,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIYbMd38M2GgbdedSQ',
          },
          {
            title: "Prada_Profile",
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: true,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds26,
            imageLarge: editedAdsLarge26,
            imagePlaceholder: editedAdsPlaceholder26,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14keYp8t680A5SUg11',
          },
          {
            title: "Dior_can't_Afford_me", 
            priceOriginal: 200,
            material: 'adverts cut from magazines with mixed media',
            sold: false,
            dimensions: 'Approx A4 (slightly larger)',
            image: editedAds27,
            imageLarge: editedAdsLarge27,
            imagePlaceholder: editedAdsPlaceholder27,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/eVa4jL6kYft21CEbKN',
          },
        ],
      },
]