import joy1 from '../../images/joy/1.jpg'
import joy2 from '../../images/joy/2.jpg'
import joy3 from '../../images/joy/3.jpg'
import joy4 from '../../images/joy/4.jpg'
import joy5 from '../../images/joy/5.jpg'
import joy6 from '../../images/joy/6.jpg'
import joy7 from '../../images/joy/7.jpg'
import joy8 from '../../images/joy/8.jpg'
import joy9 from '../../images/joy/9.jpg'
import joy10 from '../../images/joy/10.jpg'
import joy11 from '../../images/joy/11.jpg'
import joy12 from '../../images/joy/12.jpg'
import joy13 from '../../images/joy/13.jpg'
import joy14 from '../../images/joy/14.jpg'
import joy15 from '../../images/joy/15.jpg'
import joy16 from '../../images/joy/16.jpg'
import joy17 from '../../images/joy/17.jpg'

import joyLarge1 from '../../images/joy/large/1.jpg'
import joyLarge2 from '../../images/joy/large/2.jpg'
import joyLarge3 from '../../images/joy/large/3.jpg'
import joyLarge4 from '../../images/joy/large/4.jpg'
import joyLarge5 from '../../images/joy/large/5.jpg'
import joyLarge6 from '../../images/joy/large/6.jpg'
import joyLarge7 from '../../images/joy/large/7.jpg'
import joyLarge8 from '../../images/joy/large/8.jpg'
import joyLarge9 from '../../images/joy/large/9.jpg'
import joyLarge10 from '../../images/joy/large/10.jpg'
import joyLarge11 from '../../images/joy/large/11.jpg'
import joyLarge12 from '../../images/joy/large/12.jpg'
import joyLarge13 from '../../images/joy/large/13.jpg'
import joyLarge14 from '../../images/joy/large/14.jpg'
import joyLarge15 from '../../images/joy/large/15.jpg'
import joyLarge16 from '../../images/joy/large/16.jpg'
import joyLarge17 from '../../images/joy/large/17.jpg'

import joyPlaceholder1 from '../../images/joy/placeholder/1.svg'
import joyPlaceholder2 from '../../images/joy/placeholder/2.svg'
import joyPlaceholder3 from '../../images/joy/placeholder/3.svg'
import joyPlaceholder4 from '../../images/joy/placeholder/4.svg'
import joyPlaceholder5 from '../../images/joy/placeholder/5.svg'
import joyPlaceholder6 from '../../images/joy/placeholder/6.svg'
import joyPlaceholder7 from '../../images/joy/placeholder/7.svg'
import joyPlaceholder8 from '../../images/joy/placeholder/8.svg'
import joyPlaceholder9 from '../../images/joy/placeholder/9.svg'
import joyPlaceholder10 from '../../images/joy/placeholder/10.svg'
import joyPlaceholder11 from '../../images/joy/placeholder/11.svg'
import joyPlaceholder12 from '../../images/joy/placeholder/12.svg'
import joyPlaceholder13 from '../../images/joy/placeholder/13.svg'
import joyPlaceholder14 from '../../images/joy/placeholder/14.svg'
import joyPlaceholder15 from '../../images/joy/placeholder/15.svg'
import joyPlaceholder16 from '../../images/joy/placeholder/16.svg'
import joyPlaceholder17 from '../../images/joy/placeholder/17.svg'

export const joy = [
    
  {
    category: 'joy',
    projects: [
      {
        title: 'joy_1',
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy1,
        imageLarge: joyLarge1,
        imagePlaceholder: joyPlaceholder1,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/dR6bMdaBe2Gg0yA02W',
      },
      {
        title: 'joy_2',
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy2,
        imageLarge: joyLarge2,
        imagePlaceholder: joyPlaceholder2,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/dR6g2t10Ea8I6WY5nh',
      },
      {
        title: 'joy_3',
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy3,
        imageLarge: joyLarge3,
        imagePlaceholder: joyPlaceholder3,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/fZeeYp10E5Ssepq5ni',
      },
      {
        title: 'joy_4',
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy4,
        imageLarge: joyLarge4,
        imagePlaceholder: joyPlaceholder4,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/eVa9E57p2dkU0yAaHD',
      },
      {
        title: 'joy_5',
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy5,
        imageLarge: joyLarge5,
        imagePlaceholder: joyPlaceholder5,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/5kA7vX7p2gx6gxy174',
      },
      {
        title: 'joy_6',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy6,
        imageLarge: joyLarge6,
        imagePlaceholder: joyPlaceholder6,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/9AQ4jL9xagx6956bLJ',
      },
      {
        title: 'joy_7',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy7,
        imageLarge: joyLarge7,
        imagePlaceholder: joyPlaceholder7,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/28obMd7p25Ss4OQg20',
      },
      {
        title: 'joy_8',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy8,
        imageLarge: joyLarge8,
        imagePlaceholder: joyPlaceholder8,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/9AQeYp4cQgx66WY2bb',
      },
      {
        title: 'joy_9',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy9,
        imageLarge: joyLarge9,
        imagePlaceholder: joyPlaceholder9,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/7sIdUl6kY94Ebde3fg',
      },
      {
        title: 'joy_10',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy10,
        imageLarge: joyLarge10,
        imagePlaceholder: joyPlaceholder10,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/5kAbMd5gUgx63KMaHJ',
      },
      {
        title: 'joy_11',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy11,
        imageLarge: joyLarge11,
        imagePlaceholder: joyPlaceholder11,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/00g2bDcJm1Ccepqg24',
      },
      {
        title: 'joy_12',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy12,
        imageLarge: joyLarge12,
        imagePlaceholder: joyPlaceholder12,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/eVag2tfVygx6a9abLP',
      },
      {
        title: 'joy_13',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy13,
        imageLarge: joyLarge13,
        imagePlaceholder: joyPlaceholder13,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/8wM17z4cQeoYchi2bg',
      },
      {
        title: 'joy_14',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy14,
        imageLarge: joyLarge14,
        imagePlaceholder: joyPlaceholder14,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/8wM03v5gU5Ss812bLR',
      },
      {
        title: 'joy_15',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy15,
        imageLarge: joyLarge15,
        imagePlaceholder: joyPlaceholder15,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/3cs2bD6kY80A3KM7vC',
      },
      {
        title: 'joy_16',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy16,
        imageLarge: joyLarge16,
        imagePlaceholder: joyPlaceholder16,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/00gg2t6kYbcMdlm4jr',
      },
      {
        title: 'joy_17',
        pricePrint: 40,
        priceOriginal: 90,
        material: 'coloured pencil and pastels on paper',
        sold: false,
        dimensions: 'A4',
        image: joy17,
        imageLarge: joyLarge17,
        imagePlaceholder: joyPlaceholder17,
        alt: 'NFT image',
        originalStripeLink: 'https://buy.stripe.com/fZeeYp4cQ5Ssdlm3fo',
      },
    ],
  },
]