import colourCartoons0 from '../../images/coloured_cartoon/0.jpg'
import colourCartoons1 from '../../images/coloured_cartoon/1.jpg'
import colourCartoons4 from '../../images/coloured_cartoon/4.jpg'
import colourCartoons5 from '../../images/coloured_cartoon/5.jpg'
import colourCartoons7 from '../../images/coloured_cartoon/7.jpg'
import colourCartoons8 from '../../images/coloured_cartoon/8.jpg'
import colourCartoons9 from '../../images/coloured_cartoon/9.jpg'
import colourCartoons11 from '../../images/coloured_cartoon/11.jpg'
import colourCartoons12 from '../../images/coloured_cartoon/12.jpg'
import colourCartoons13 from '../../images/coloured_cartoon/13.jpg'
import colourCartoons14 from '../../images/coloured_cartoon/14.jpg'
import colourCartoons15 from '../../images/coloured_cartoon/15.jpg'
import colourCartoons16 from '../../images/coloured_cartoon/16.jpg'
import colourCartoons17 from '../../images/coloured_cartoon/17.jpg'
import colourCartoons18 from '../../images/coloured_cartoon/18.jpg'
import colourCartoons19 from '../../images/coloured_cartoon/19.jpg'
import colourCartoons20 from '../../images/coloured_cartoon/20.jpg'
import colourCartoons21 from '../../images/coloured_cartoon/21.jpg'
import colourCartoons22 from '../../images/coloured_cartoon/22.jpg'
import colourCartoons23 from '../../images/coloured_cartoon/23.jpg'
import colourCartoons24 from '../../images/coloured_cartoon/24.jpg'
import colourCartoons25 from '../../images/coloured_cartoon/25.jpg'
import colourCartoons26 from '../../images/coloured_cartoon/26.jpg'
import colourCartoons27 from '../../images/coloured_cartoon/27.jpg'
import colourCartoons28 from '../../images/coloured_cartoon/28.jpg'

import colourCartoonsLarge0 from '../../images/coloured_cartoon/large/0.jpg'
import colourCartoonsLarge1 from '../../images/coloured_cartoon/large/1.jpg'
import colourCartoonsLarge4 from '../../images/coloured_cartoon/large/4.jpg'
import colourCartoonsLarge5 from '../../images/coloured_cartoon/large/5.jpg'
import colourCartoonsLarge7 from '../../images/coloured_cartoon/large/7.jpg'
import colourCartoonsLarge8 from '../../images/coloured_cartoon/large/8.jpg'
import colourCartoonsLarge9 from '../../images/coloured_cartoon/large/9.jpg'
import colourCartoonsLarge11 from '../../images/coloured_cartoon/large/11.jpg'
import colourCartoonsLarge12 from '../../images/coloured_cartoon/large/12.jpg'
import colourCartoonsLarge13 from '../../images/coloured_cartoon/large/13.jpg'
import colourCartoonsLarge14 from '../../images/coloured_cartoon/large/14.jpg'
import colourCartoonsLarge15 from '../../images/coloured_cartoon/large/15.jpg'
import colourCartoonsLarge16 from '../../images/coloured_cartoon/large/16.jpg'
import colourCartoonsLarge17 from '../../images/coloured_cartoon/large/17.jpg'
import colourCartoonsLarge18 from '../../images/coloured_cartoon/large/18.jpg'
import colourCartoonsLarge19 from '../../images/coloured_cartoon/large/19.jpg'
import colourCartoonsLarge20 from '../../images/coloured_cartoon/large/20.jpg'
import colourCartoonsLarge21 from '../../images/coloured_cartoon/large/21.jpg'
import colourCartoonsLarge22 from '../../images/coloured_cartoon/large/22.jpg'
import colourCartoonsLarge23 from '../../images/coloured_cartoon/large/23.jpg'
import colourCartoonsLarge24 from '../../images/coloured_cartoon/large/24.jpg'
import colourCartoonsLarge25 from '../../images/coloured_cartoon/large/25.jpg'
import colourCartoonsLarge26 from '../../images/coloured_cartoon/large/26.jpg'
import colourCartoonsLarge27 from '../../images/coloured_cartoon/large/27.jpg'
import colourCartoonsLarge28 from '../../images/coloured_cartoon/large/28.jpg'

import colourCartoonsPlaceholder0 from '../../images/coloured_cartoon/placeholder/0.svg'
import colourCartoonsPlaceholder1 from '../../images/coloured_cartoon/placeholder/1.svg'
import colourCartoonsPlaceholder4 from '../../images/coloured_cartoon/placeholder/4.svg'
import colourCartoonsPlaceholder5 from '../../images/coloured_cartoon/placeholder/5.svg'
import colourCartoonsPlaceholder7 from '../../images/coloured_cartoon/placeholder/7.svg'
import colourCartoonsPlaceholder8 from '../../images/coloured_cartoon/placeholder/8.svg'
import colourCartoonsPlaceholder9 from '../../images/coloured_cartoon/placeholder/9.svg'
import colourCartoonsPlaceholder11 from '../../images/coloured_cartoon/placeholder/11.svg'
import colourCartoonsPlaceholder12 from '../../images/coloured_cartoon/placeholder/12.svg'
import colourCartoonsPlaceholder13 from '../../images/coloured_cartoon/placeholder/13.svg'
import colourCartoonsPlaceholder14 from '../../images/coloured_cartoon/placeholder/14.svg'
import colourCartoonsPlaceholder15 from '../../images/coloured_cartoon/placeholder/15.svg'
import colourCartoonsPlaceholder16 from '../../images/coloured_cartoon/placeholder/16.svg'
import colourCartoonsPlaceholder17 from '../../images/coloured_cartoon/placeholder/17.svg'
import colourCartoonsPlaceholder18 from '../../images/coloured_cartoon/placeholder/18.svg'
import colourCartoonsPlaceholder19 from '../../images/coloured_cartoon/placeholder/19.svg'
import colourCartoonsPlaceholder20 from '../../images/coloured_cartoon/placeholder/20.svg'
import colourCartoonsPlaceholder21 from '../../images/coloured_cartoon/placeholder/21.svg'
import colourCartoonsPlaceholder22 from '../../images/coloured_cartoon/placeholder/22.svg'
import colourCartoonsPlaceholder23 from '../../images/coloured_cartoon/placeholder/23.svg'
import colourCartoonsPlaceholder24 from '../../images/coloured_cartoon/placeholder/24.svg'
import colourCartoonsPlaceholder25 from '../../images/coloured_cartoon/placeholder/25.svg'
import colourCartoonsPlaceholder26 from '../../images/coloured_cartoon/placeholder/26.svg'
import colourCartoonsPlaceholder27 from '../../images/coloured_cartoon/placeholder/27.svg'
import colourCartoonsPlaceholder28 from '../../images/coloured_cartoon/placeholder/28.svg'

export const colouredCartoons = [
    {
        category: 'coloured_cartoon',
        projects: [
          {
            title: 'your_dad',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons5,
            imageLarge: colourCartoonsLarge5,
            imagePlaceholder: colourCartoonsPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZe8A15gU80Achi3eU',
          },
          {
            title: 'fake_suns',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons0,
            imageLarge: colourCartoonsLarge0,
            imagePlaceholder: colourCartoonsPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQ03v9xa0y84OQg1B',
          },
          {
            title: 'artifical_consciousness',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons1,
            imageLarge: colourCartoonsLarge1,
            imagePlaceholder: colourCartoonsPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14k2bD10E80Aa9adTu',
          },
          {
            title: 'bots_run_the_economy',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons4,
            imageLarge: colourCartoonsLarge4,
            imagePlaceholder: colourCartoonsPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/cN23fH6kYeoYgxy8zd',
          },
          {
            title: 'death',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons7,
            imageLarge: colourCartoonsLarge7,
            imagePlaceholder: colourCartoonsPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEUaI99xa5Ss812g1I',
          },
          {
            title: 'take_ur_pill',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons8,
            imageLarge: colourCartoonsLarge8,
            imagePlaceholder: colourCartoonsPlaceholder8,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE9E54cQgx65SU9Dl',
          },
          {
            title: 'blue_guy',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons9,
            imageLarge: colourCartoonsLarge9,
            imagePlaceholder: colourCartoonsPlaceholder9,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wM4jL7p294E0yA6ra',
          },
          {
            title: 'conspiracy',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons11,
            imageLarge: colourCartoonsLarge11,
            imagePlaceholder: colourCartoonsPlaceholder11,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIY8A110E94E81216S',
          },
          {
            title: 'DRAWING_PIN',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons12,
            imageLarge: colourCartoonsLarge12,
            imagePlaceholder: colourCartoonsPlaceholder12,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14k7vXfVy2Gg956bMH',
          },
          {
            title: 'PORTAL',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons13,
            imageLarge: colourCartoonsLarge13,
            imagePlaceholder: colourCartoonsPlaceholder13,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/eVaeYp10Ea8Igxy7ws',
          },
          {
            title: 'VICAR',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons14,
            imageLarge: colourCartoonsLarge14,
            imagePlaceholder: colourCartoonsPlaceholder14,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZe03v6kY94Edlm7wt',
          },
          {
            title: 'GREEN_KING',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons15,
            imageLarge: colourCartoonsLarge15,
            imagePlaceholder: colourCartoonsPlaceholder15,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/4gw2bD7p24Oo6WYbMK',
          },
          {
            title: 'BARTIN_ROUGE',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons16,
            imageLarge: colourCartoonsLarge16,
            imagePlaceholder: colourCartoonsPlaceholder16,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28o2bD7p26Ww0yA6sr',
          },
          {
            title: 'DETRIOT',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons17,
            imageLarge: colourCartoonsLarge17,
            imagePlaceholder: colourCartoonsPlaceholder17,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kA7vXcJm3Kk1CE9EE',
          },
          {
            title: 'KITE',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons18,
            imageLarge: colourCartoonsLarge18,
            imagePlaceholder: colourCartoonsPlaceholder18,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/4gw17z38Ma8Ia9a7wx',
          },
          {
            title: 'LADDER',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons19,
            imageLarge: colourCartoonsLarge19,
            imagePlaceholder: colourCartoonsPlaceholder19,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEUcQhfVy3Kk5SU8AC',
          },
          {
            title: 'TV',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: true,
            dimensions: 'A4',
            image: colourCartoons20,
            imageLarge: colourCartoonsLarge20,
            imagePlaceholder: colourCartoonsPlaceholder20,
            alt: 'NFT image',
            originalStripeLink: '',
          },
          {
            title: 'WHEEL',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons21,
            imageLarge: colourCartoonsLarge21,
            imagePlaceholder: colourCartoonsPlaceholder21,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQ3fH8t6bcMepq3gj',
          },
          {
            title: 'FABRIC',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons22,
            imageLarge: colourCartoonsLarge22,
            imagePlaceholder: colourCartoonsPlaceholder22,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kA2bDaBeft26WYg36',
          },
          {
            title: 'PUNCTURED_CIRCLE',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons23,
            imageLarge: colourCartoonsLarge23,
            imagePlaceholder: colourCartoonsPlaceholder23,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28o4jLeRuft25SUaIN',
          },
          {
            title: 'BETTING_SLIP',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons24,
            imageLarge: colourCartoonsLarge24,
            imagePlaceholder: colourCartoonsPlaceholder24,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/00gcQh24I5Ss956bMS',
          },
          {
            title: 'COLOUR_IS_THE_MESSAGE',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons25,
            imageLarge: colourCartoonsLarge25,
            imagePlaceholder: colourCartoonsPlaceholder25,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/7sIdUlfVy6Ww1CEbMT',
          },
          {
            title: 'ZOOM',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons26,
            imageLarge: colourCartoonsLarge26,
            imagePlaceholder: colourCartoonsPlaceholder26,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQ5nPaBedkU5SU7wE',
          },
          {
            title: 'SHRUB_DANCE',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons27,
            imageLarge: colourCartoonsLarge27,
            imagePlaceholder: colourCartoonsPlaceholder27,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28og2t5gU5Ssa9adV3',
          },
          {
            title: 'MORE_GUM',
            priceOriginal: 150,
            material: 'Pen, Spray paint and pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: colourCartoons28,
            imageLarge: colourCartoonsLarge28,
            imagePlaceholder: colourCartoonsPlaceholder28,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEU17z9xacgQ6WY5oy',
          },
        ],
    },
]