import { shopContent } from '../content/shopContent'
import { NFTContent } from '../content/NFTContent'

import * as qs from 'query-string'
import { find } from 'lodash'

export const getPageData = ({ location, isNFTPage }) => {
  const query = qs.parse(location.search)
  const { category } = query
  const thjing = isNFTPage ? NFTContent : shopContent
  console.log('thjing', thjing)
  const currentPage = thjing.filter(page => page.category === category)[0].category
  return find(thjing, page => page.category === currentPage).projects
}
