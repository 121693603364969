import React from 'react'
import videoOne from '../videos/video-page/1.MP4'
import videoTwo from '../videos/video-page/2.MP4'
import videoThree from '../videos/video-page/3.mp4'

import videoOnePlaceholder from '../videos/video-page-1.svg'
import videoTwoPlaceholder from '../videos/video-page-2.svg'
import videoThreePlaceholder from '../videos/video-page-3.svg'

export const VideoContent = [
  {
    title: 'first video',
    url: videoThree,
    placeholder: videoThreePlaceholder,
  },
  {
    title: 'second video',
    url: videoOne,
    placeholder: videoTwoPlaceholder,
  },
  {
    title: 'third video',
    url: videoTwo,
    placeholder: videoOnePlaceholder,
  },
]
