import nightBus0 from '../../images/night_bus/0.jpg'
import nightBus1 from '../../images/night_bus/1.jpg'
import nightBus2 from '../../images/night_bus/2.jpg'
import nightBus3 from '../../images/night_bus/3.jpg'
import nightBus4 from '../../images/night_bus/4.jpg'

import nightBusLarge0 from '../../images/night_bus/0.jpg'
import nightBusLarge1 from '../../images/night_bus/1.jpg'
import nightBusLarge2 from '../../images/night_bus/2.jpg'
import nightBusLarge3 from '../../images/night_bus/3.jpg'
import nightBusLarge4 from '../../images/night_bus/4.jpg'

import nightBusPlaceholder0 from '../../images/night_bus/placeholder/0.svg'
import nightBusPlaceholder1 from '../../images/night_bus/placeholder/1.svg'
import nightBusPlaceholder2 from '../../images/night_bus/placeholder/2.svg'
import nightBusPlaceholder3 from '../../images/night_bus/placeholder/3.svg'
import nightBusPlaceholder4 from '../../images/night_bus/placeholder/4.svg'

export const nightBus = [
    {
        category: 'night_bus',
        projects: [
          {
            title: 'SLEEPING_COUPLE',
            priceOriginal: 200,
            material: 'Ink on Paper',
            sold: false,
            dimensions: 'A5',
            image: nightBus0, 
            imageLarge: nightBusLarge0,
            imagePlaceholder: nightBusPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/cN24jLfVyeoYgxy17h',
          },
          {
            title: 'DUBLIN_STREET',
            priceOriginal: 200,
            material: 'Ink on Paper',
            sold: false,
            dimensions: 'A5',
            image: nightBus1,
            imageLarge: nightBusLarge1,
            imagePlaceholder: nightBusPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kAdUl10Egx63KMg2c',
          },
          {
            title: 'PASSENGER',
            priceOriginal: 200,
            material: 'Ink on Paper',
            sold: false,
            dimensions: 'A5',
            image: nightBus2,
            imageLarge: nightBusLarge2,
            imagePlaceholder: nightBusPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/4gw03v7p20y80yAbLX',
          },
          {
            title: 'DOMESTIC',
            priceOriginal: 200,
            material: 'Ink on Paper',
            sold: false,
            dimensions: 'A5',
            image: nightBus3,
            imageLarge: nightBusLarge3,
            imagePlaceholder: nightBusPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3cs17z10E2Ggepq03g',
          },
          {
            title: 'GARY',
            priceOriginal: 200,
            material: 'Ink on Paper',
            sold: false,
            dimensions: 'A5',
            image: nightBus4,
            imageLarge: nightBusLarge4,
            imagePlaceholder: nightBusPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wM6rTaBe1Ccgxyg2f',
          },
        ],
    },
]