import funny0 from '../../images/funny/0.jpg'
import funny1 from '../../images/funny/1.jpg'
import funny2 from '../../images/funny/2.jpg'
import funny3 from '../../images/funny/3.jpg'
import funny4 from '../../images/funny/4.jpg'
import funny5 from '../../images/funny/5.jpg'
import funny6 from '../../images/funny/6.jpg'
import funny7 from '../../images/funny/7.jpg'
import funny8 from '../../images/funny/8.jpg'
import funny9 from '../../images/funny/9.jpg'

import funnyLarge0 from '../../images/funny/large/0.jpg'
import funnyLarge1 from '../../images/funny/large/1.jpg'
import funnyLarge2 from '../../images/funny/large/2.jpg'
import funnyLarge3 from '../../images/funny/large/3.jpg'
import funnyLarge4 from '../../images/funny/large/4.jpg'
import funnyLarge5 from '../../images/funny/large/5.jpg'
import funnyLarge6 from '../../images/funny/large/6.jpg'
import funnyLarge7 from '../../images/funny/large/7.jpg'
import funnyLarge8 from '../../images/funny/large/8.jpg'
import funnyLarge9 from '../../images/funny/large/9.jpg'

import funnyPlaceholder0 from '../../images/funny/placeholder/0.svg'
import funnyPlaceholder1 from '../../images/funny/placeholder/1.svg'
import funnyPlaceholder2 from '../../images/funny/placeholder/2.svg'
import funnyPlaceholder3 from '../../images/funny/placeholder/3.svg'
import funnyPlaceholder4 from '../../images/funny/placeholder/4.svg'
import funnyPlaceholder5 from '../../images/funny/placeholder/5.svg'
import funnyPlaceholder6 from '../../images/funny/placeholder/6.svg'
import funnyPlaceholder7 from '../../images/funny/placeholder/7.svg'
import funnyPlaceholder8 from '../../images/funny/placeholder/8.svg'
import funnyPlaceholder9 from '../../images/funny/placeholder/9.svg'

export const funny = [
    {
        category: 'funny',
        projects: [
          {
            title: 'Sammy_Davis_Junior',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny2,
            imageLarge: funnyLarge2,
            imagePlaceholder: funnyPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIY03v6kYa8Iepq5lO',
          },
          {
            title: 'The_Soviet_Union',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny0,
            imageLarge: funnyLarge0,
            imagePlaceholder: funnyPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQbMdfVya8I4OQ01v',
          },
          {
            title: 'All_the_Tennis_Players_I_can_think_of',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny1,
            imageLarge: funnyLarge1,
            imagePlaceholder: funnyPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kAaI99xaft20yA4hM',
          },
          {
            title: "I'm_Not_Thinking_about_my_Audience",
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny3,
            imageLarge: funnyLarge3,
            imagePlaceholder: funnyPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wM2bD24I0y895629F',
          },
          {
            title: 'Crave/Get_Cycle',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny4,
            imageLarge: funnyLarge4,
            imagePlaceholder: funnyPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kA5nP4cQdkU1CE8y4',
          },
          {
            title: 'Might_Delete_Later',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny5,
            imageLarge: funnyLarge5,
            imagePlaceholder: funnyPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZe9E510Eft22GI29H',
          },
          {
            title: 'GNVQ_Level_1',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny6,
            imageLarge: funnyLarge6,
            imagePlaceholder: funnyPlaceholder6,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3cs2bD10Eft25SU4hQ',
          },
          {
            title: 'Closh',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny7,
            imageLarge: funnyLarge7,
            imagePlaceholder: funnyPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIY5nPbFi5Ss956g0z',
          },
          {
            title: 'Candle',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny8,
            imageLarge: funnyLarge8,
            imagePlaceholder: funnyPlaceholder8,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE4jLgZC0y8bde5lW',
          },
          {
            title: 'What?!',
            pricePrint: 40,
            priceOriginal: 100,
            material: 'pen on paper',
            sold: false,
            dimensions: 'A4',
            image: funny9,
            imageLarge: funnyLarge9,
            imagePlaceholder: funnyPlaceholder9,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/00g3fH9xabcMbde9Cd',
          },
        ],
    },
]