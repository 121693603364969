import { workIsHell } from './workIsHell'
import { detailedPenAndInk } from './detailedPenAndInk'
import { editedAds } from './editedAds'
import { colouredCartoons } from './colouredCartoons'
import { colouredFaces } from './colouredFaces'
import { pastelSet } from './pastelSet'
import { funny } from './funny'
import { joy } from './joy'
import { a6Drawings } from './a6Drawings'
import { nightBus } from './nightBus'
import { multiPen } from './multiPen'
import { asemic } from './asemic'
import { squaresAndCircle } from './squaresAndCircle'

export const shopContent = [
...workIsHell,
...detailedPenAndInk,
...editedAds,
...colouredCartoons,
...colouredFaces,
...pastelSet,
...funny,
...joy,
...a6Drawings,
...nightBus,
...multiPen,
...asemic,
...squaresAndCircle
]
