import colourFaces0 from '../../images/coloured_faces/0.jpg'
import colourFaces1 from '../../images/coloured_faces/1.jpg'
import colourFaces2 from '../../images/coloured_faces/2.jpg'
import colourFaces3 from '../../images/coloured_faces/3.jpg'
import colourFaces4 from '../../images/coloured_faces/4.jpg'
import colourFaces5 from '../../images/coloured_faces/5.jpg'
import colourFaces6 from '../../images/coloured_faces/6.jpg'
import colourFaces7 from '../../images/coloured_faces/7.jpg'

import colourFacesLarge0 from '../../images/coloured_faces/large/0.jpg'
import colourFacesLarge1 from '../../images/coloured_faces/large/1.jpg'
import colourFacesLarge2 from '../../images/coloured_faces/large/2.jpg'
import colourFacesLarge3 from '../../images/coloured_faces/large/3.jpg'
import colourFacesLarge4 from '../../images/coloured_faces/large/4.jpg'
import colourFacesLarge5 from '../../images/coloured_faces/large/5.jpg'
import colourFacesLarge6 from '../../images/coloured_faces/large/6.jpg'
import colourFacesLarge7 from '../../images/coloured_faces/large/7.jpg'

import colourFacesPlaceholder0 from '../../images/coloured_faces/placeholder/0.svg'
import colourFacesPlaceholder1 from '../../images/coloured_faces/placeholder/1.svg'
import colourFacesPlaceholder2 from '../../images/coloured_faces/placeholder/2.svg'
import colourFacesPlaceholder3 from '../../images/coloured_faces/placeholder/3.svg'
import colourFacesPlaceholder4 from '../../images/coloured_faces/placeholder/4.svg'
import colourFacesPlaceholder5 from '../../images/coloured_faces/placeholder/5.svg'
import colourFacesPlaceholder6 from '../../images/coloured_faces/placeholder/6.svg'
import colourFacesPlaceholder7 from '../../images/coloured_faces/placeholder/7.svg'

export const colouredFaces = [
    {
        category: 'coloured_faces',
        projects: [
          {
            title: 'man',
            sold: true,
            dimensions: 'A4',
            material: 'Coloured pencil on paper',
            image: colourFaces5,
            imageLarge: colourFacesLarge5,
            imagePlaceholder: colourFacesPlaceholder5,
            printStripeLink: 'https://buy.stripe.com/8wM4jL5gUbcM95615b',
          },
          {
            title: 'lonely_boy',
            priceOriginal: 200,
            sold: false,
            dimensions: 'A4',
            material: 'Coloured pencil on paper',
            image: colourFaces0,
            imageLarge: colourFacesLarge0,
            imagePlaceholder: colourFacesPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28o5nP24IbcM4OQdTm',
          },
          {
            title: 'different_types_of_brears',
            priceOriginal: 200,
            sold: false,
            dimensions: 'A4',
            material: 'Coloured pencil on paper',
            image: colourFaces1,
            imageLarge: colourFacesLarge1,
            imagePlaceholder: colourFacesPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEUaI9dNq4Ooftu02x',
          },
          {
            title: 'lady',
            priceOriginal: 200,
            sold: false,
            dimensions: 'A4',
            material: 'Coloured pencil on paper',
            image: colourFaces2,
            imageLarge: colourFacesLarge2,
            imagePlaceholder: colourFacesPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wMaI9gZCgx6gxy7v0',
          },
    
          {
            title: 'street_cleaner',
            priceOriginal: 200,
            sold: false,
            dimensions: 'A4',
            material: 'Coloured pencil on paper',
            image: colourFaces3,
            imageLarge: colourFacesLarge3,
            imagePlaceholder: colourFacesPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3cs4jLgZCeoYftug1x',
          },
          {
            title: 'comfort',
            priceOriginal: 200,
            sold: false,
            dimensions: 'A4',
            material: 'Coloured pencil on paper',
            image: colourFaces4,
            imageLarge: colourFacesLarge4,
            imagePlaceholder: colourFacesPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wMbMd10EeoY812bLi',
          },
          {
            title: 'young_priest',
            priceOriginal: 200,
            sold: false,
            dimensions: 'A4',
            material: 'Coloured pencil on paper',
            image: colourFaces6,
            imageLarge: colourFacesLarge6,
            imagePlaceholder: colourFacesPlaceholder6,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/cN23fH8t63Kk956cPn',
          },
          {
            title: 'old_boy',
            priceOriginal: 200,
            sold: false,
            dimensions: 'A4',
            material: 'Coloured pencil on paper',
            image: colourFaces7,
            imageLarge: colourFacesLarge7,
            imagePlaceholder: colourFacesPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEUaI95gUeoYepq5mW',
          },
        ],
    },
]