import pastelSet0 from '../../images/pastel_set/0.jpg'
import pastelSet5 from '../../images/pastel_set/5.jpg'
import pastelSet6 from '../../images/pastel_set/6.jpg'
import pastelSet7 from '../../images/pastel_set/7.jpg'
import pastelSet8 from '../../images/pastel_set/8.jpg'
import pastelSet9 from '../../images/pastel_set/9.jpg'
import pastelSet10 from '../../images/pastel_set/10.jpg'
import pastelSet11 from '../../images/pastel_set/11.jpg'
import pastelSet12 from '../../images/pastel_set/12.jpg'

import pastelSetLarge0 from '../../images/pastel_set/large/0.jpg'
import pastelSetLarge5 from '../../images/pastel_set/large/5.jpg'
import pastelSetLarge6 from '../../images/pastel_set/large/6.jpg'
import pastelSetLarge7 from '../../images/pastel_set/large/7.jpg'
import pastelSetLarge8 from '../../images/pastel_set/large/8.jpg'
import pastelSetLarge9 from '../../images/pastel_set/large/9.jpg'
import pastelSetLarge10 from '../../images/pastel_set/large/10.jpg'
import pastelSetLarge11 from '../../images/pastel_set/large/11.jpg'
import pastelSetLarge12 from '../../images/pastel_set/large/12.jpg'

import pastelSetPlaceholder0 from '../../images/pastel_set/placeholder/0.svg'
import pastelSetPlaceholder5 from '../../images/pastel_set/placeholder/5.svg'
import pastelSetPlaceholder6 from '../../images/pastel_set/placeholder/6.svg'
import pastelSetPlaceholder7 from '../../images/pastel_set/placeholder/7.svg'
import pastelSetPlaceholder8 from '../../images/pastel_set/placeholder/8.svg'
import pastelSetPlaceholder9 from '../../images/pastel_set/placeholder/9.svg'
import pastelSetPlaceholder10 from '../../images/pastel_set/placeholder/10.svg'
import pastelSetPlaceholder11 from '../../images/pastel_set/placeholder/11.svg'
import pastelSetPlaceholder12 from '../../images/pastel_set/placeholder/12.svg'

export const pastelSet = [
    {
        category: 'pastel_set',
        projects: [
          {
            title: 'yo!',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet10,
            imageLarge: pastelSetLarge10,
            imagePlaceholder: pastelSetPlaceholder10,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/4gw5nPdNqeoYgxydSW',
          },
          {
            title: 'Sleeper',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet0,
            imageLarge: pastelSetLarge0,
            imagePlaceholder: pastelSetPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE2bDcJmgx62GI16b',
          },
          {
            title: 'dog',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet5,
            imageLarge: pastelSetLarge5,
            imagePlaceholder: pastelSetPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE9E5eRu6Ww1CE3eo',
          },
          {
            title: 'park_bench',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet6,
            imageLarge: pastelSetLarge6,
            imagePlaceholder: pastelSetPlaceholder6,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/00g8A15gU0y8bdebKV',
          },
          {
            title: 'sinister_minister',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet7,
            imageLarge: pastelSetLarge7,
            imagePlaceholder: pastelSetPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIYbMd4cQ2Gg9567uG',
          },
          {
            title: 'creature',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet8,
            imageLarge: pastelSetLarge8,
            imagePlaceholder: pastelSetPlaceholder8,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEU9E55gU6Ww6WY9CP',
          },
          {
            title: 'grey_land',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet9,
            imageLarge: pastelSetLarge9,
            imagePlaceholder: pastelSetPlaceholder9,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14k9E538MeoYepq16k',
          },
          {
            title: 'codependent',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet11,
            imageLarge: pastelSetLarge11,
            imagePlaceholder: pastelSetPlaceholder11,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/3cs17z6kYbcM2GI2ap',
          },
          {
            title: 'structures_on_the_land',
            priceOriginal: 150,
            material: 'Chalk pastel on paper',
            sold: false,
            dimensions: 'A4',
            image: pastelSet12,
            imageLarge: pastelSetLarge12,
            imagePlaceholder: pastelSetPlaceholder12,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/4gw17z6kYbcM1CEbL0',
          },
        ],
    },
]