import squaresAndCircles0 from '../../images/squares_and_circles/0.jpg'
import squaresAndCircles1 from '../../images/squares_and_circles/1.jpg'
import squaresAndCircles2 from '../../images/squares_and_circles/2.jpg'
import squaresAndCircles3 from '../../images/squares_and_circles/3.jpg'
import squaresAndCircles4 from '../../images/squares_and_circles/4.jpg'
import squaresAndCircles5 from '../../images/squares_and_circles/5.jpg'
import squaresAndCircles6 from '../../images/squares_and_circles/6.jpg'
import squaresAndCircles7 from '../../images/squares_and_circles/7.jpg'
import squaresAndCircles8 from '../../images/squares_and_circles/8.jpg'
import squaresAndCircles9 from '../../images/squares_and_circles/9.jpg'
import squaresAndCircles10 from '../../images/squares_and_circles/10.jpg'
import squaresAndCircles11 from '../../images/squares_and_circles/11.jpg'
import squaresAndCircles12 from '../../images/squares_and_circles/12.jpg'
import squaresAndCircles13 from '../../images/squares_and_circles/13.jpg'
import squaresAndCircles14 from '../../images/squares_and_circles/14.jpg'

import squaresAndCirclesLarge0 from '../../images/squares_and_circles/large/0.jpg'
import squaresAndCirclesLarge1 from '../../images/squares_and_circles/large/1.jpg'
import squaresAndCirclesLarge2 from '../../images/squares_and_circles/large/2.jpg'
import squaresAndCirclesLarge3 from '../../images/squares_and_circles/large/3.jpg'
import squaresAndCirclesLarge4 from '../../images/squares_and_circles/large/4.jpg'
import squaresAndCirclesLarge5 from '../../images/squares_and_circles/large/5.jpg'
import squaresAndCirclesLarge6 from '../../images/squares_and_circles/large/6.jpg'
import squaresAndCirclesLarge7 from '../../images/squares_and_circles/large/7.jpg'
import squaresAndCirclesLarge8 from '../../images/squares_and_circles/large/8.jpg'
import squaresAndCirclesLarge9 from '../../images/squares_and_circles/large/9.jpg'
import squaresAndCirclesLarge10 from '../../images/squares_and_circles/large/10.jpg'
import squaresAndCirclesLarge11 from '../../images/squares_and_circles/large/11.jpg'
import squaresAndCirclesLarge12 from '../../images/squares_and_circles/large/12.jpg'
import squaresAndCirclesLarge13 from '../../images/squares_and_circles/large/13.jpg'
import squaresAndCirclesLarge14 from '../../images/squares_and_circles/large/14.jpg'

import squaresAndCirclesPlaceholder0 from '../../images/squares_and_circles/placeholder/0.svg'
import squaresAndCirclesPlaceholder1 from '../../images/squares_and_circles/placeholder/1.svg'
import squaresAndCirclesPlaceholder2 from '../../images/squares_and_circles/placeholder/2.svg'
import squaresAndCirclesPlaceholder3 from '../../images/squares_and_circles/placeholder/3.svg'
import squaresAndCirclesPlaceholder4 from '../../images/squares_and_circles/placeholder/4.svg'
import squaresAndCirclesPlaceholder5 from '../../images/squares_and_circles/placeholder/5.svg'
import squaresAndCirclesPlaceholder6 from '../../images/squares_and_circles/placeholder/6.svg'
import squaresAndCirclesPlaceholder7 from '../../images/squares_and_circles/placeholder/7.svg'
import squaresAndCirclesPlaceholder8 from '../../images/squares_and_circles/placeholder/8.svg'
import squaresAndCirclesPlaceholder9 from '../../images/squares_and_circles/placeholder/9.svg'
import squaresAndCirclesPlaceholder10 from '../../images/squares_and_circles/placeholder/10.svg'
import squaresAndCirclesPlaceholder11 from '../../images/squares_and_circles/placeholder/11.svg'
import squaresAndCirclesPlaceholder12 from '../../images/squares_and_circles/placeholder/12.svg'
import squaresAndCirclesPlaceholder13 from '../../images/squares_and_circles/placeholder/13.svg'
import squaresAndCirclesPlaceholder14 from '../../images/squares_and_circles/placeholder/14.svg'

export const squaresAndCircle = [
    {
        category: 'squares_and_circles',
        projects: [
          {
            title: 'CIRCLE_1',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: true,
            dimensions: 'A4',
            image: squaresAndCircles0,
            imageLarge: squaresAndCirclesLarge0,
            imagePlaceholder: squaresAndCirclesPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: '',
          },
          {
            title: 'SQUARE_1',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles1,
            imageLarge: squaresAndCirclesLarge1,
            imagePlaceholder: squaresAndCirclesPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQ03v24IeoY812cR1',
          },
          {
            title: 'SQUARE_2',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles2,
            imageLarge: squaresAndCirclesLarge2,
            imagePlaceholder: squaresAndCirclesPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZe2bD7p24Oochig3e',
          },
          {
            title: 'SQUARE_3',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles3,
            imageLarge: squaresAndCirclesLarge3,
            imagePlaceholder: squaresAndCirclesPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/00gaI924I94Edlm3gt',
          },
          {
            title: 'SQUARE_4',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles4,
            imageLarge: squaresAndCirclesLarge4,
            imagePlaceholder: squaresAndCirclesPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/00gbMdcJm80A0yA2cq',
          },
          {
            title: 'SQUARE_5',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles5,
            imageLarge: squaresAndCirclesLarge5,
            imagePlaceholder: squaresAndCirclesPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28o7vX4cQdkU6WYdV9',
          },
          {
            title: 'CIRCLE_2',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles6,
            imageLarge: squaresAndCirclesLarge6,
            imagePlaceholder: squaresAndCirclesPlaceholder6,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIYcQh24I6Wwftu2cs',
          },
          {
            title: 'SQUARE_6',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles7,
            imageLarge: squaresAndCirclesLarge7,
            imagePlaceholder: squaresAndCirclesPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kA6rTaBe2Gg3KMbN3',
          },
          {
            title: 'CIRCLE_3',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles8,
            imageLarge: squaresAndCirclesLarge8,
            imagePlaceholder: squaresAndCirclesPlaceholder8,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/00gg2taBe6Wwa9a4kC',
          },
          {
            title: 'SQUARE_7',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles9,
            imageLarge: squaresAndCirclesLarge9,
            imagePlaceholder: squaresAndCirclesPlaceholder9,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wM2bDeRua8I5SUbN5',
          },
          {
            title: 'CIRCLE_4',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles10,
            imageLarge: squaresAndCirclesLarge10,
            imagePlaceholder: squaresAndCirclesPlaceholder10,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kA6rT8t6cgQgxycRa',
          },
          {
            title: 'SQUARE_8',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles11,
            imageLarge: squaresAndCirclesLarge11,
            imagePlaceholder: squaresAndCirclesPlaceholder11,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQ2bDcJm94EepqeZj',
          },
          {
            title: 'SQUARE_9',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles12,
            imageLarge: squaresAndCirclesLarge12,
            imagePlaceholder: squaresAndCirclesPlaceholder12,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZe7vX7p20y83KM3gC',
          },
          {
            title: 'CIRCLE_5',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles14,
            imageLarge: squaresAndCirclesLarge14,
            imagePlaceholder: squaresAndCirclesPlaceholder14,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14kg2t4cQbcMchi4kH',
          },
          {
            title: 'CIRCLE_6',
            priceOriginal: 200,
            material: 'Coloured pen and pencil on paper',
            sold: false,
            dimensions: 'A4',
            image: squaresAndCircles13,
            imageLarge: squaresAndCirclesLarge13,
            imagePlaceholder: squaresAndCirclesPlaceholder13,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/aEU8A1gZCdkUchi8AY',
          },
        ],
      },
]