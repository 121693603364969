

import a6Drawings0 from '../images/a6_drawings/0.jpg'
import a6Drawings1 from '../images/a6_drawings/1.jpg'
import a6Drawings2 from '../images/a6_drawings/2.jpg'
import a6Drawings3 from '../images/a6_drawings/3.jpg'
import a6Drawings4 from '../images/a6_drawings/4.jpg'
import a6Drawings5 from '../images/a6_drawings/5.jpg'
import a6Drawings6 from '../images/a6_drawings/6.jpg'

import a6DrawingsLarge0 from '../images/a6_drawings/large/0.jpg'
import a6DrawingsLarge1 from '../images/a6_drawings/large/1.jpg'
import a6DrawingsLarge2 from '../images/a6_drawings/large/2.jpg'
import a6DrawingsLarge3 from '../images/a6_drawings/large/3.jpg'
import a6DrawingsLarge4 from '../images/a6_drawings/large/4.jpg'
import a6DrawingsLarge5 from '../images/a6_drawings/large/5.jpg'
import a6DrawingsLarge6 from '../images/a6_drawings/large/6.jpg'

export const NFTContent = [
  {
    category: 'a6_drawings',
    projects: [
      {
        title: 'a6_drawings_1',
        NFTPrice: 0.4,
        sold: false,
        dimensions: 'A4',
        image: a6Drawings0,
        imageLarge: a6DrawingsLarge0,
        alt: 'NFT image',
        NFTLink: 'https://foundation.app/',
        type: 'NFT'
      },
      {
        title: 'a6_drawings_2',
        NFTPrice: 0.7,
        sold: false,
        dimensions: 'A4',
        image: a6Drawings1,
        imageLarge: a6DrawingsLarge1,
        alt: 'NFT image',
        NFTLink: 'https://foundation.app/',
        type: 'NFT'
      },
      {
        title: 'a6_drawings_3',
        NFTPrice: 0.1,
        sold: false,
        dimensions: 'A4',
        image: a6Drawings2,
        imageLarge: a6DrawingsLarge2,
        alt: 'NFT image',
        NFTLink: 'https://foundation.app/',
        type: 'NFT'
      },
      {
        title: 'a6_drawings_4',
        NFTPrice: 0.5,
        sold: false,
        dimensions: 'A4',
        image: a6Drawings3,
        imageLarge: a6DrawingsLarge3,
        alt: 'NFT image',
        NFTLink: 'https://foundation.app/',
        type: 'NFT'
      },
      {
        title: 'a6_drawings_5',
        NFTPrice: 0.1,
        sold: false,
        dimensions: 'A4',
        image: a6Drawings4,
        imageLarge: a6DrawingsLarge4,
        alt: 'NFT image',
        printStripeLink: 'https://buy.stripe.com/cN2aI9cJm0y8epq6pk',
        originalStripeLink: 'https://buy.stripe.com/8wM9E5gZCgx62GIcPc',
        NFTLink: 'https://foundation.app/',
        type: 'NFT'
      },
      {
        title: 'a6_drawings_6',
        NFTPrice: 0.4,
        sold: false,
        dimensions: 'A4',
        image: a6Drawings5,
        imageLarge: a6DrawingsLarge5,
        alt: 'NFT image',
        NFTLink: 'https://foundation.app/',
        type: 'NFT'
      },
      {
        title: 'a6_drawings_7',
        NFTPrice: 0.2,
        sold: false,
        dimensions: 'A4',
        image: a6Drawings6,
        imageLarge: a6DrawingsLarge6,
        alt: 'NFT image',
        NFTLink: 'https://foundation.app/',
        type: 'NFT'
      },
    ],
  },
]