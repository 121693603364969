import detailedPenAndInk0 from '../../images/detailed_pen_and_ink/0.jpg'
import detailedPenAndInk1 from '../../images/detailed_pen_and_ink/1.jpg'
import detailedPenAndInk2 from '../../images/detailed_pen_and_ink/2.jpg'
import detailedPenAndInk3 from '../../images/detailed_pen_and_ink/3.jpg'
import detailedPenAndInk4 from '../../images/detailed_pen_and_ink/4.jpg'
import detailedPenAndInk5 from '../../images/detailed_pen_and_ink/5.jpg'
import detailedPenAndInk6 from '../../images/detailed_pen_and_ink/6.jpg'
import detailedPenAndInk7 from '../../images/detailed_pen_and_ink/7.jpg'
import detailedPenAndInk8 from '../../images/detailed_pen_and_ink/8.jpg'

import detailedPenAndInkLarge0 from '../../images/detailed_pen_and_ink/large/0.jpg'
import detailedPenAndInkLarge1 from '../../images/detailed_pen_and_ink/large/1.jpg'
import detailedPenAndInkLarge2 from '../../images/detailed_pen_and_ink/large/2.jpg'
import detailedPenAndInkLarge3 from '../../images/detailed_pen_and_ink/large/3.jpg'
import detailedPenAndInkLarge4 from '../../images/detailed_pen_and_ink/large/4.jpg'
import detailedPenAndInkLarge5 from '../../images/detailed_pen_and_ink/large/5.jpg'
import detailedPenAndInkLarge6 from '../../images/detailed_pen_and_ink/large/6.jpg'
import detailedPenAndInkLarge7 from '../../images/detailed_pen_and_ink/large/7.jpg'
import detailedPenAndInkLarge8 from '../../images/detailed_pen_and_ink/large/8.jpg'

import detailedPenAndInkPlaceholder0 from '../../images/detailed_pen_and_ink/placeholder/0.svg'
import detailedPenAndInkPlaceholder1 from '../../images/detailed_pen_and_ink/placeholder/1.svg'
import detailedPenAndInkPlaceholder2 from '../../images/detailed_pen_and_ink/placeholder/2.svg'
import detailedPenAndInkPlaceholder3 from '../../images/detailed_pen_and_ink/placeholder/3.svg'
import detailedPenAndInkPlaceholder4 from '../../images/detailed_pen_and_ink/placeholder/4.svg'
import detailedPenAndInkPlaceholder5 from '../../images/detailed_pen_and_ink/placeholder/5.svg'
import detailedPenAndInkPlaceholder6 from '../../images/detailed_pen_and_ink/placeholder/6.svg'
import detailedPenAndInkPlaceholder7 from '../../images/detailed_pen_and_ink/placeholder/7.svg'
import detailedPenAndInkPlaceholder8 from '../../images/detailed_pen_and_ink/placeholder/8.svg'

export const detailedPenAndInk = [
    {
        category: 'detailed_pen_and_ink',
        projects: [
          {
            title: 'Terraces',
            priceOriginal: 600,
            material: 'Pen and ink on paper',
            sold: false,
            dimensions: 'A4',
            image: detailedPenAndInk5,
            imageLarge: detailedPenAndInkLarge5,
            imagePlaceholder: detailedPenAndInkPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/14k9E538MbcMepq16X',
          },
          {
            title: 'Moon',
            priceOriginal: 500,
            material: 'Pen and ink on paper',
            sold: false,
            dimensions: 'A4 (Slightly smaller)',
            image: detailedPenAndInk0,
            imageLarge: detailedPenAndInkLarge0,
            imagePlaceholder: detailedPenAndInkPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/bIY2bD9xa80Abde7vh',
          },
          {
            title: 'GERMAN_POINTER_IN_THE_LANDSCAPE',
            priceOriginal: 500,
            material: 'Pen and ink on paper',
            sold: true,
            dimensions: 'A4',
            image: detailedPenAndInk8,
            imageLarge: detailedPenAndInkLarge8,
            imagePlaceholder: detailedPenAndInkPlaceholder8,
            alt: 'NFT image',
            originalStripeLink: '',
          },
          {
            title: 'Three_Nights_on_Ffinch_Street',
            priceOriginal: 500,
            material: 'Pen and ink on paper',
            sold: false,
            dimensions: '',
            image: detailedPenAndInk1,
            imageLarge: detailedPenAndInkLarge1,
            imagePlaceholder: detailedPenAndInkPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/cN2aI9bFigx6epqeZn',
          },
          {
            title: 'AFTER_GUERNICA',
            priceOriginal: 800,
            material: 'Pen and ink on paper',
            sold: false,
            dimensions: 'A3',
            image: detailedPenAndInk2,
            imageLarge: detailedPenAndInkLarge2,
            imagePlaceholder: detailedPenAndInkPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE9E56kYa8Ia9a8zn',
          },
          {
            title: 'NOO_SPHERE',
            priceOriginal: 800,
            material: 'Pen and ink on paper',
            sold: false,
            dimensions: 'A3',
            image: detailedPenAndInk3,
            imageLarge: detailedPenAndInkLarge3,
            imagePlaceholder: detailedPenAndInkPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oEdUl4cQ0y8bdeg3s',
          },
          {
            title: 'Armchair_Man',
            priceOriginal: 700,
            material: 'Pen and ink on paper',
            sold: false,
            dimensions: 'A4',
            image: detailedPenAndInk4,
            imageLarge: detailedPenAndInkLarge4,
            imagePlaceholder: detailedPenAndInkPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/7sI3fHaBe6WwchidTI',
          },
          {
            title: 'Last_Supper',
            sold: true,
            dimensions: '',
            image: detailedPenAndInk6,
            imageLarge: detailedPenAndInkLarge6,
            imagePlaceholder: detailedPenAndInkPlaceholder6,
            printStripeLink: 'https://buy.stripe.com/8wM17z10E0y8gxyaG7',
          },
          {
            title: 'Rackham',
            priceOriginal: 1500,
            material: 'Pen and ink on paper',
            sold: false,
            dimensions: 'A2',
            image: detailedPenAndInk7,
            imageLarge: detailedPenAndInkLarge7,
            imagePlaceholder: detailedPenAndInkPlaceholder7,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/dR6aI95gUgx6dlm5ne',
          },
        ],
      },
]