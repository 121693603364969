import asemic0 from '../../images/asemic_writing/0.jpg'
import asemic1 from '../../images/asemic_writing/1.jpg'
import asemic2 from '../../images/asemic_writing/2.jpg'
import asemic3 from '../../images/asemic_writing/3.jpg'
import asemic4 from '../../images/asemic_writing/4.jpg'

import asemicLarge0 from '../../images/asemic_writing/large/0.jpg'
import asemicLarge1 from '../../images/asemic_writing/large/1.jpg'
import asemicLarge2 from '../../images/asemic_writing/large/2.jpg'
import asemicLarge3 from '../../images/asemic_writing/large/3.jpg'
import asemicLarge4 from '../../images/asemic_writing/large/4.jpg'

import asemicPlaceholder0 from '../../images/asemic_writing/placeholder/0.svg'
import asemicPlaceholder1 from '../../images/asemic_writing/placeholder/1.svg'
import asemicPlaceholder2 from '../../images/asemic_writing/placeholder/2.svg'
import asemicPlaceholder3 from '../../images/asemic_writing/placeholder/3.svg'
import asemicPlaceholder4 from '../../images/asemic_writing/placeholder/4.svg'

// GOOGLE DRIVE TOOP LEFT 
  // 200
export const asemic = [
    {
        category: 'asemic_writing',
        projects: [
          {
            title: 'ASEMIC_WRITING_1',
            priceOriginal: 250,
            material: 'Gold paint pen on black paper',
            sold: false,
            dimensions: 'A4',
            image: asemic1,
            imageLarge: asemicLarge1,
            imagePlaceholder: asemicPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oE17zfVy3Kkbde2c3',
          },
          {
            title: 'ASEMIC_WRITING_2',
            priceOriginal: 150,
            material: 'Gold paint pen on black paper',
            sold: false,
            dimensions: 'A4',
            image: asemic0,
            imageLarge: asemicLarge0,
            imagePlaceholder: asemicPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/8wMbMdfVy4Oo3KM3g8',
          },
          {
            title: 'ASEMIC_WRITING_3',
            priceOriginal: 150,
            material: 'Gold paint pen on black paper',
            sold: false,
            dimensions: 'A4',
            image: asemic2,
            imageLarge: asemicLarge2,
            imagePlaceholder: asemicPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/6oEeYpcJm4Oochi181',
          },
          {
            title: 'ASEMIC_WRITING_4',
            priceOriginal: 150,
            material: 'Gold paint pen on black paper',
            sold: false,
            dimensions: 'A4',
            image: asemic3,
            imageLarge: asemicLarge3,
            imagePlaceholder: asemicPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZeg2tcJm2Gg956g2W',
          },
          // {
          //   title: 'ASEMIC_WRITING_5',
          //   pricePrint: 40,
          //   priceOriginal: 200,
          // material: 'Gold paint pen on black paper',
          //   sold: false,
          //   dimensions: 'A4',
          //   image: asemic4,
          //   imageLarge: asemicLarge4,
          //   imagePlaceholder: asemicPlaceholder4,
          //   alt: 'NFT image',
          //   originalStripeLink: '',
          // },
        ],
      },
]