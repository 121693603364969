import React from 'react'
import styles from '../styles/Button.module.css'
import { BiArrowBack } from "react-icons/bi";
import { BiHome } from "react-icons/bi";

export const PrimaryButton = ({ text, handleOnClick, style, className, icon }) => {
  return (
    <div className={styles.buttonWrapper} style={style}>
      <button className={styles.button} onClick={handleOnClick}>
        {text && text}
        {icon && <BiArrowBack size="15" />}
      </button>
    </div>
  )
}
