import a6Drawings0 from '../../images/a6_drawings/0.jpg'
import a6Drawings1 from '../../images/a6_drawings/1.jpg'
import a6Drawings2 from '../../images/a6_drawings/2.jpg'
import a6Drawings3 from '../../images/a6_drawings/3.jpg'
import a6Drawings4 from '../../images/a6_drawings/4.jpg'
import a6Drawings5 from '../../images/a6_drawings/5.jpg'
import a6Drawings6 from '../../images/a6_drawings/6.jpg'

import a6DrawingsLarge0 from '../../images/a6_drawings/large/0.jpg'
import a6DrawingsLarge1 from '../../images/a6_drawings/large/1.jpg'
import a6DrawingsLarge2 from '../../images/a6_drawings/large/2.jpg'
import a6DrawingsLarge3 from '../../images/a6_drawings/large/3.jpg'
import a6DrawingsLarge4 from '../../images/a6_drawings/large/4.jpg'
import a6DrawingsLarge5 from '../../images/a6_drawings/large/5.jpg'
import a6DrawingsLarge6 from '../../images/a6_drawings/large/6.jpg'

import a6DrawingsPlaceholder0 from '../../images/a6_drawings/placeholder/0.svg'
import a6DrawingsPlaceholder1 from '../../images/a6_drawings/placeholder/1.svg'
import a6DrawingsPlaceholder2 from '../../images/a6_drawings/placeholder/2.svg'
import a6DrawingsPlaceholder3 from '../../images/a6_drawings/placeholder/3.svg'
import a6DrawingsPlaceholder4 from '../../images/a6_drawings/placeholder/4.svg'
import a6DrawingsPlaceholder5 from '../../images/a6_drawings/placeholder/5.svg'
import a6DrawingsPlaceholder6 from '../../images/a6_drawings/placeholder/6.svg'

export const a6Drawings = [
    {
        category: 'a6_drawings',
        projects: [
          {
            title: 'WE_ARE_ALL_GOING_OUT',
            material: 'Pen on paper',
            priceOriginal: 150,
            sold: false,
            dimensions: 'A6',
            image: a6Drawings0,
            imageLarge: a6DrawingsLarge0,
            imagePlaceholder: a6DrawingsPlaceholder0,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28o8A110E4OochicQA',
          },
          {
            title: 'FATHER_AND_SON',
            material: 'Pen on paper',
            priceOriginal: 150,
            sold: false,
            dimensions: 'A6',
            image: a6Drawings1,
            imageLarge: a6DrawingsLarge1,
            imagePlaceholder: a6DrawingsPlaceholder1,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28obMd6kY4Oo4OQ8Al',
          },
          {
            title: 'TRANSCENDENCE',
            material: 'Pen on paper',
            pricePrint: 40,
            priceOriginal: 175,
            sold: false,
            dimensions: 'A6',
            image: a6Drawings2,
            imageLarge: a6DrawingsLarge2,
            imagePlaceholder: a6DrawingsPlaceholder2,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/28ocQhgZCa8I1CE8Am',
          },
          {
            title: 'THE_CAPTAIN',
            material: 'Pen on paper',
            priceOriginal: 150,
            sold: false,
            dimensions: 'A6',
            image: a6Drawings3,
            imageLarge: a6DrawingsLarge3,
            imagePlaceholder: a6DrawingsPlaceholder3,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/fZe9E538M5Ss2GIeYL',
          },
          {
            title: 'HIBERNATION',
            material: 'Pen on paper',
            priceOriginal: 150,
            sold: false,
            dimensions: 'A6',
            image: a6Drawings4,
            imageLarge: a6DrawingsLarge4,
            imagePlaceholder: a6DrawingsPlaceholder4,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/5kA6rTfVy0y84OQ7wk',
          },
          {
            title: 'AUTUMN',
            material: 'Pen on paper',
            pricePrint: 40,
            priceOriginal: 150,
            sold: false,
            dimensions: 'A6',
            image: a6Drawings5,
            imageLarge: a6DrawingsLarge5,
            imagePlaceholder: a6DrawingsPlaceholder5,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQ9E5bFi1Cc956bMB',
          },
          {
            title: 'WILD_ANIMAL',
            material: 'Pen on paper',
            pricePrint: 40,
            priceOriginal: 150,
            sold: false,
            dimensions: 'A6',
            image: a6Drawings6,
            imageLarge: a6DrawingsLarge6,
            imagePlaceholder: a6DrawingsPlaceholder6,
            alt: 'NFT image',
            originalStripeLink: 'https://buy.stripe.com/9AQeYpcJmbcM3KM7wm',
          },
        ],
      },
]