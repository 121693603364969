import React, { useState } from 'react'
import './App.css'

import { HorizontalScrollingWrapper } from './components/HorizontalScrollingWrapper'
import { Sidebar } from './components/Sidebar'
import { VideoContent } from './content/VideoContent'

const Videos = () => {
  const [visibleContent, setVisibleContent] = useState([])
  const handleElementOnScreen = element => {
    element.style.opacity = 1
    const index = Number(element?.getAttribute('index'))
    setVisibleContent(VideoContent[index])
  }

  return (
    <div className="shop-wrapper-padding">
      <HorizontalScrollingWrapper handleElementOnScreen={handleElementOnScreen}>
        {VideoContent?.map((video, index) => (
          <>
            <div className="video-wrapper">
              <div className="video-container">
                <div className="image-index-container" index={index}>
                  <video poster={video?.placeholder} controls src={`${video?.url}#t=0.10`}></video>
                </div>
              </div>
            </div>
          </>
        ))}
      </HorizontalScrollingWrapper>

      <Sidebar title={visibleContent?.title} />
    </div>
  )
}

export default Videos
